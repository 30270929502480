<template>
<div id="page" class="hierarchy-page-index">
  <div class="hpi-statistical-part" v-loading="countLoading">
    <statistical :data="statisticalData" />
  </div>
  <div class="hpi-list-part">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="keyword">
        <el-input v-model="form.keyword" placeholder="姓名/联系方式" clearable>
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="代理地区" prop="txt_area_code">
        <el-cascader v-model="form.txt_area_code" :options="regionOptions" :props="{checkStrictly: true, value: 'txt_code', label: 'txt_area_name', children: '_child'}" />
      </el-form-item>
      <el-form-item label="根据合伙人筛选" prop="t_pagent_id">
        <el-cascader v-model="form.t_pagent_id" :options="partnerOption" :props="{checkStrictly: true, value: 't_agent_id', label: 'txt_realname', children: '_child'}" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading="tabelLoading">
      <el-table-column prop="txt_realname" label="姓名" align="center" />
      <el-table-column prop="txt_mobile" label="预留手机号" align="center" />
      <el-table-column prop="txt_area_name" label="代理地区" align="center" />
      <el-table-column prop="txt_agent_level" label="代理级别" align="center" />
      <el-table-column prop="txt_pname" label="上级代理" align="center">
        <template #default="scope">{{scope.row.txt_pname||"- -"}}</template>
      </el-table-column>
      <el-table-column prop="txt_count_partner" label="子级代理" align="center" />
      <el-table-column prop="dec_divided" label="已分润金额（元）" align="center">
        <template #default="scope">{{scope.row.dec_divided||"0"}}</template>
      </el-table-column>
      <el-table-column prop="dt_agent" label="代理时间" align="center">
        <template #default="scope">{{scope.row.dt_agent||"- -"}}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="table-option">
        <template #default="scope">
          <div class="table-icon" @click="checkDetail(scope.row)">
            <el-icon>
              <Document />
            </el-icon> 详情
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  </div>
</div>
</template>

<script>
// 合伙人管理 - 层级关系概览
import { onMounted, reactive, toRefs, ref } from 'vue';
import { Statistical, Pagination } from "components";
import { Document } from "@element-plus/icons-vue";
import { getLevels, getAreas, levelsCount, getPartnersSearch } from "api/apis.js";
import { ElMessage, FormInstance } from 'element-plus';
export default {
  components: {
    Statistical,
    Document,
    Pagination
  },
  setup() {
    const state = reactive({
      formRef: ref(FormInstance),
      statisticalData: [
        { key: "cnt_province", end: "人", value: 0, zn: "省级合伙人", eg: "Province partners" },
        { key: "cnt_city", end: "人", value: 0, zn: "市级合伙人", eg: "City partners" },
        { key: "cnt_salesman", end: "人", value: 0, zn: "市场专员", eg: "Marketing specialist" },
        { key: "cnt_clinic", end: "人", value: 0, zn: "诊所", eg: "Clinic" }
      ],
      regionOptions: [],
      partnerOption: [],
      form: {
        keyword: null,
        t_pagent_id: null,
        txt_area_code: null,
        page: 1,
        limit: 10
      },
      tableData: [],
      tabelLoading: false,
      total: 0,
      countLoading: false,
    });

    onMounted(() => {
      getAreaOption();
      getPartners();
      getCount();
      getList();
    });

    // 查看详情
    const checkDetail = (item) => {
      console.log(item)
    };

    // 获取地区信息
    const getAreaOption = () => {
      getAreas().then(response => {
        if (response.code == 200 && response.data) {
          state.regionOptions = response.data;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 获取合伙人信息 - 选项
    const getPartners = () => {
      getPartnersSearch({int_agent_level: 3}).then(response => {
        if (response.code == 200 && response.data) {
          state.partnerOption = response.data;
        }
      })
    }

    // 层级关系统计
    const getCount = () => {
      state.countLoading = true;
      levelsCount().then(response => {
        if (response.code == 200) {
          let keys = Object.keys(response.data);
          for (let i = 0; i < keys.length; i++) {
            let index = state.statisticalData.findIndex(t => t.key == keys[i]);
            if (index != -1) {
              state.statisticalData[index].value = response.data[keys[i]];
            }
          }
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.countLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.formRef.resetFields();
      getList();
    };

    // 获取列表数据
    const getList = () => {
      // 请求参数赋值
      let param = {
        keyword: (state.form.keyword || "").trim() == "" ? null : state.form.keyword,
        t_pagent_id: (state.form.t_pagent_id || []).length > 0 ? state.form.t_pagent_id[state.form.t_pagent_id.length-1] : null,
        txt_area_code: (state.form.txt_area_code || []).length > 0 ? state.form.txt_area_code[state.form.txt_area_code.length-1] : null,
        page: state.form.page,
        limit: state.form.limit
      }
      let keys = Object.keys(param);
      // 值为null的字段不需要传
      for (let i = 0; i < keys.length; i++) {
        if (param[keys[i]] == null) {
          delete param[keys[i]];
        }
      }
      state.tabelLoading = true;
      getLevels(param).then(response => {
        if (response.code == 200 && response.data) {
          state.tableData = response.data.data;
          state.total = response.data.row;
        } else {
          state.tableData = [];
          state.total = 0;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.tabelLoading = false;
      })
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      getAreaOption,
      getCount,
      checkDetail,
      getPartners,
    };
  }
}
</script>

<style lang="scss" scoped>
.hierarchy-page-index {
  .hpi-list-part {
    margin-top: 24px;
    height: calc(100% - 150px);

    .el-table {
      height: calc(100% - 100px);
    }
  }
}
</style>
